import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "styled-components"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import { ContainerStatics, TitleH1Statics } from "../components/global"
import IcLocation from "../images/svgs/ic_location.svg"
import IcSupport from "../images/svgs/ic_support.svg"

const ContactPage = ({ location, intl }) => {
  const SEOTitle = intl.formatMessage({ id: "SEO_title_contact" })
  const SEODescription = intl.formatMessage({ id: "SEO_description_contact" })

  return (
    <Layout {...{ location }}>
      <SEO title={SEOTitle} description={SEODescription} />
      <ContainerStaticsStyled>
        <TitleH1Statics>
          {intl.formatMessage({ id: "main_title_contact" })}
        </TitleH1Statics>
        <ActionsWrapper>
          <Element
            href="https://goo.gl/maps/cfF1UcNVLvxsomtf6"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="svg">
              <IcLocation />
            </div>
            <div className="text">
              <h6>{intl.formatMessage({ id: "title_find_us_contact" })}</h6>
              <p>{intl.formatMessage({ id: "text_find_us_contact" })}</p>
            </div>
          </Element>
          <ElementNoLink>
            <IcSupport />
            <div className="text">
              <h6>{intl.formatMessage({ id: "title_contact_us_contact" })}</h6>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:support@durcal.com"
                >
                  support@durcal.com
                </a>
              </p>
              <h6 className="second-title">
                {intl.formatMessage({ id: "marketing_contact_us_contact" })}
              </h6>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:marketing@durcal.com"
                >
                  marketing@durcal.com
                </a>
              </p>
            </div>
          </ElementNoLink>
        </ActionsWrapper>
      </ContainerStaticsStyled>
    </Layout>
  )
}

const ContainerStaticsStyled = styled(ContainerStatics)`
  padding-top: 142px;
  padding-bottom: 120px;
  min-height: 76vh;

  @media (max-width: ${props => props.theme.screen.lg}) {
    padding-top: 130px;
  }
`
const ActionsWrapper = styled.div`
  display: flex;
  margin-top: 40px;

  @media (max-width: ${props => props.theme.screen.lg}) {
    margin-top: 56px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const ElementNoLink = styled.div`
  width: 50%;
  height: 200px;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 40px;
  cursor: pointer;

  .second-title {
    padding-top: 12px;
    display: block;
  }

  .text {
    margin-left: 24px;
    max-width: 280px;
    h6 {
      color: ${({ theme }) => theme.color.primary};
      ${({ theme }) => theme.font_size.small};
      margin: 0;

      @media (max-width: ${props => props.theme.screen.md}) {
        ${({ theme }) => theme.font_size.xsmall};
      }
    }
    p {
      ${({ theme }) => theme.font_size.xsmall};
      color: ${({ theme }) => theme.color.black.greydark};
      margin: 0;
      font-family: ${({ theme }) => theme.font.medium};

      @media (max-width: ${props => props.theme.screen.md}) {
        ${({ theme }) => theme.font_size.xxsmall};
      }
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
      margin: 20px 0 0 0;
      text-align: center;
    }
  }

  .svg {
    width: 80px;
    height: 80px;
  }

  &:last-child {
    margin-left: 22px;
  }

  &:hover {
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: ${props => props.theme.screen.lg}) {
    height: 160px;
    padding: 20px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    margin-bottom: 16px;
    height: 236px;
    flex-direction: column;
    justify-content: center;

    &:last-child {
      margin-left: 0;
    }
  }
`

const Element = styled.a`
  width: 50%;
  height: 200px;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 40px;
  cursor: pointer;

  .text {
    margin-left: 24px;
    max-width: 280px;
    h6 {
      color: ${({ theme }) => theme.color.primary};
      ${({ theme }) => theme.font_size.small};
      margin: 0;

      @media (max-width: ${props => props.theme.screen.md}) {
        ${({ theme }) => theme.font_size.xsmall};
      }
    }
    p {
      ${({ theme }) => theme.font_size.xsmall};
      color: ${({ theme }) => theme.color.black.greydark};
      margin: 0;
      font-family: ${({ theme }) => theme.font.medium};

      @media (max-width: ${props => props.theme.screen.md}) {
        ${({ theme }) => theme.font_size.xxsmall};
      }
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
      margin: 20px 0 0 0;
      text-align: center;
    }
  }

  .svg {
    width: 80px;
    height: 80px;
  }

  &:last-child {
    margin-left: 22px;
  }

  &:hover {
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: ${props => props.theme.screen.lg}) {
    height: 160px;
    padding: 20px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    margin-bottom: 16px;
    height: 236px;
    flex-direction: column;
    justify-content: center;

    &:last-child {
      margin-left: 0;
    }
  }
`

export default injectIntl(ContactPage)
